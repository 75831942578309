import React, { useState } from 'react';
import './App.css';

const App = () => {
  const [transcript, setTranscript] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  let mediaRecorder;

  const startRecording = () => {
    setIsRecording(true);
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = (event) => {
          setAudioChunks((prev) => [...prev, event.data]);
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.play();

          // For now, mock transcription output.
          setTranscript('This is a mock transcription.');
        };

        mediaRecorder.start();
      })
      .catch((error) => {
        console.error('Error accessing audio:', error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    setIsRecording(false);
  };

  const handleTranscription = () => {
    // Future: Add functionality to send audioBlob to a transcription service
    alert('Transcription process will be implemented here.');
  };

  return (
    <div className="App">
      <h1>Medical Scribe MVP</h1>
      <button onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? 'Stop Recording' : 'Start Recording'}
      </button>
      <button onClick={handleTranscription} disabled={!audioChunks.length}>
        Transcribe
      </button>
      <div>
        <h2>Transcript</h2>
        <p>{transcript}</p>
      </div>
    </div>
  );
};

export default App;
